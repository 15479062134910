import { Col, Input, Row, Select, InputNumber, message } from 'antd';
import React from 'react';
import { MaskedInput } from "antd-mask-input";
import { capitalizeFirstLetter } from 'utils/GlobalFunctions';
const { Option } = Select;
const UiFormSingle = props => {
    const { element, errorField, setErrorField, userData, setUserData, data } = props;



    const getUiElement = () => {
        if (element.ui_type === "1") {
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <Input
                    value={data[element.reg_column_name]}
                    placeholder={element.reg_column_name}
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e) => {
                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = e.target.value;
                        setUserData({
                            ...tmpData
                        });
                    }}
                />
            </Col>
        } else if (element.ui_type === "2") {
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <InputNumber
                    min={1}
                    max={100}
                    placeholder={element.reg_column_name}
                    size="small"
                    value={data[element.reg_column_name]}
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e) => {
                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = e;
                        setUserData({
                            ...tmpData
                        });
                    }}
                    step={1}
                />
            </Col>
        } else if (element.ui_type === "3") {
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <Select
                    placeholder={element.reg_column_name}
                    value={data[element.reg_column_name] == undefined ? null : data[element.reg_column_name]}
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-select-error`
                            : `common-select`
                    }

                    onSelect={(v) => {
                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = v;
                        setUserData({
                            ...tmpData
                        });
                    }}
                >
                    {
                        element.dropdown_list && element.dropdown_list.split(",").map((dropItem, dropIndex) => {
                            return <Option value={dropItem} key={dropIndex}>{dropItem}</Option>
                        })
                    }
                </Select>
            </Col>
        } else if (element.ui_type === "4") {
            return <Col xs={24} sm={12}>
                <div style={{ paddingBottom: "5px" }}>
                    {element.name}
                    {element.is_required == "true" ? "*" : ""}
                </div>
                <MaskedInput
                    mask={"000-000-0000"}
                    value={data[element.reg_column_name]}
                    className={
                        errorField !== undefined &&
                            errorField.includes(`${element.reg_column_name}`)
                            ? `common-input-error`
                            : `common-input`
                    }
                    onChange={(e) => {
                        if (element.is_required == "true" && errorField.includes(`${element.reg_column_name}`)) {
                            let errors = errorField.filter(
                                (y) => y != `${element.reg_column_name}`
                            );
                            setErrorField([...errors]);
                        }
                        let tmpData = { ...userData };
                        tmpData[element.reg_column_name] = e;
                        setUserData({
                            ...tmpData
                        });
                    }}
                />
            </Col>
        }
    }
    return (
        <>
            {getUiElement()}
        </>
    );
};

export default UiFormSingle;
