// @flow strict

import { Modal, Row } from "antd";
import * as React from "react";
import { confirmable } from "react-confirm";
import "./MyAlertDialog.css";
function MyAlertDialog(props) {
  const { show, proceed, } = props;
  const { title, content } = props;
  let curDomain = window.location.hostname;
  if (curDomain.trim() == "localhost" || curDomain.trim() == "192.168.100.81") {
    curDomain = "devtuxedocamp.beclutch.club";
  }
  return (
    <Modal
      visible={show}
      destroyOnClose={true}
      onCancel={() => {
        proceed(null);
      }}
      className="alert-rec-modal"
      closable={true}
      maskClosable={false}
      title={title}
      footer={null}
    >
      <p className="rec-alert-title">Registration is Currently CLOSED</p>
      {curDomain.includes("yaccamp") ?
        <p className="rec-alert-content">Please contact <a href="mailto:activities@monroeny.org">activities@monroeny.org</a> with any questions</p>
        :
        <p className="rec-alert-content">Please contact <a href="mailto:kmetcalf@tuxedogov.org">kmetcalf@tuxedogov.org</a> with any questions</p>
      }
    </Modal>
  );
}

export default confirmable(MyAlertDialog);
